// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-day-en-js": () => import("./../src/pages/IndustryDay-en.js" /* webpackChunkName: "component---src-pages-industry-day-en-js" */),
  "component---src-pages-industry-day-no-js": () => import("./../src/pages/IndustryDay-no.js" /* webpackChunkName: "component---src-pages-industry-day-no-js" */)
}

